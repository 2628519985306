//  margin

.margin_0 {
  margin: 0px !important;
}
.margin_i {
  margin: 1px !important;
}
.margin_ii {
  margin: 2px !important;
}
.margin_iii {
  margin: 3px !important;
}
.margin_iv {
  margin: 4px !important;
}
.margin_v {
  margin: 5px !important;
}
.margin_vi {
  margin: 6px !important;
}
.margin_vii {
  margin: 7px !important;
}
.margin_viii {
  margin: 8px !important;
}
.margin_ix {
  margin: 9px !important;
}
.margin_x {
  margin: 10px !important;
}
.margin_xi {
  margin: 11px !important;
}
.margin_xii {
  margin: 12px !important;
}
.margin_xiii {
  margin: 13px !important;
}
.margin_xiv {
  margin: 14px !important;
}
.margin_xv {
  margin: 15px !important;
}
.margin_xvi {
  margin: 16px !important;
}
.margin_xvii {
  margin: 17px !important;
}
.margin_xviii {
  margin: 18px !important;
}
.margin_xix {
  margin: 19px !important;
}
.margin_xx {
  margin: 20px !important;
}

.margin_top_v {
  margin-top: 5px !important;
}
.margin_top_x {
  margin-top: 10px !important;
}
.margin_top_xv {
  margin-top: 15px !important;
}
.margin_bottom_v {
  margin-bottom: 5px !important;
}
.margin_bottom_x {
  margin-bottom: 10px !important;
}
.margin_bottom_xv {
  margin-bottom: 15px !important;
}

//  padding

.padding_0 {
  padding: 0px !important;
}
.padding_i {
  padding: 1px !important;
}
.padding_ii {
  padding: 2px !important;
}
.padding_iii {
  padding: 3px !important;
}
.padding_iv {
  padding: 4px !important;
}
.padding_v {
  padding: 5px !important;
}
.padding_vi {
  padding: 6px !important;
}
.padding_vii {
  padding: 7px !important;
}
.padding_viii {
  padding: 8px !important;
}
.padding_ix {
  padding: 9px !important;
}
.padding_x {
  padding: 10px !important;
}
.padding_xi {
  padding: 11px !important;
}
.padding_xii {
  padding: 12px !important;
}
.padding_xiii {
  padding: 13px !important;
}
.padding_xiv {
  padding: 14px !important;
}
.padding_xv {
  padding: 15px !important;
}
.padding_xvi {
  padding: 16px !important;
}
.padding_xvii {
  padding: 17px !important;
}
.padding_xviii {
  padding: 18px !important;
}
.padding_xix {
  padding: 19px !important;
}
.padding_xx {
  padding: 20px !important;
}
.padding_top_v {
  padding-top: 5px !important;
}
.padding_top_x {
  padding-top: 10px !important;
}
.padding_top_xv {
  padding-top: 15px !important;
}
.padding_bottom_v {
  padding-bottom: 5px !important;
}
.padding_bottom_x {
  padding-bottom: 10px !important;
}
.padding_bottom_xv {
  padding-bottom: 15px !important;
}

//  font size

.font_size_x {
  font-size: 10px !important;
}
.font_size_xi {
  font-size: 11px !important;
}
.font_size_xii {
  font-size: 12px !important;
}
.font_size_xiii {
  font-size: 13px !important;
}
.font_size_xiv {
  font-size: 14px !important;
}
.font_size_xv {
  font-size: 15px !important;
}
.font_size_xvi {
  font-size: 16px !important;
}
.font_size_xvii {
  font-size: 17px !important;
}
.font_size_xviii {
  font-size: 18px !important;
}
.font_size_xix {
  font-size: 19px !important;
}
.font_size_xx {
  font-size: 20px !important;
}
.font_size_xxx {
  font-size: 30px !important;
}
ion-content {
  font-size: 14px !important;
}
.display_flex {
  display: flex;
  justify-content: space-between;
}
.pull_left {
  float: left !important;
}
.pull_right {
  float: right !important;
}
.primary {
  color: var(--ion-color-primary) !important;
}
.secondary {
  color: var(--ion-color-secondary) !important;
}
.gray {
  color: gray !important;
}
.white {
  color: white !important;
}
.font_4 {
  font-weight: 400 !important;
}
.font_5 {
  font-weight: 500 !important;
}
.font_6 {
  font-weight: 600 !important;
}
.font_7 {
  font-weight: 700 !important;
}
