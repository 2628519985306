.content {
  --background: #f5f8fd !important;
  font-size: 14px;
  font-weight: 400px !important;
}
._title {
  font-size: 20px;
  font-weight: bold !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.app_logo {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: var(--ion-color-secondary);
}
.app_name {
  display: inline-block;
  height: 30px;
  width: auto;
}
.app_name .app_title {
  font-size: 22px;
  margin: 8px 10px;
  color: var(--ion-color-primary);
  font-weight: 500;
}
.signup_des {
  font-size: 22px;
  margin-top: 20px;
  color: var(--ion-color-secondary) !important;
}
.signup_des b {
  font-weight: 500;
}
.general_block {
  margin: 20px;
}
.border_input {
  height: 50px;
  border: 1px solid #c5c4c4;
  border-radius: 15px;
  padding: 0px 10px !important;
}
.border_input:hover {
  border: 1px solid var(--ion-color-primary);
}
.password_input {
  padding-right: 50px !important;
}
.passwordhide_icon {
  position: absolute;
  right: 15px;
  font-size: 25px;
  color: gray;
}
p {
  margin-top: 12px;
}
.terms_check {
  height: 20px;
  width: 20px;
  position: relative;
  top: 4px;
  right: 10px;
}
ion-checkbox {
  --checkmark-color: white;
}
.register_btn {
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  border-radius: 15px;
  --border-radius: 15px;
  color: white;
  width: 100%;
  text-transform: none;
}
.progress {
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: radial-gradient(circle at center, white 60%, transparent 70%),
    linear-gradient(to right, #eeeded 50%, var(--ion-color-primary) 0);
}
.progress_percentage {
  margin: 0px;
  padding: 15px 12px;
  font-size: 15px;
  color: var(--ion-color-primary);
}
.signup_user {
  margin-bottom: 0px !important;
}
.signup_user b {
  font-weight: normal !important;
  color: var(--ion-color-primary);
}
.user_there {
  color: gray;
}
.footer_btn {
  padding: 20px 40px 30px;
}
.tab_bottom {
  height: 100px;
}
.menu_btn {
  height: 16px;
  margin: 7px 15px 0px;
}
.general_card {
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #ebf0f9;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(198, 218, 248, 0.254152);
  border-radius: 16px;
}
.search {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  ::ng-deep {
    .searchbar-search-icon {
      box-shadow: none;
      height: 25px;
      width: 25px;
      top: 8px;
      color: rgb(163, 162, 162);
    }
    .searchbar-input-container {
      .searchbar-input {
        background: rgba(237, 241, 247, 0.5);
        border: 0.5px solid rgba(143, 155, 179, 0.5);
        box-shadow: none;
        border-radius: 10px;
      }
    }
  }
}
.cartitem_count {
  font-size: 14px;
  background: var(--ion-color-primary);
  color: #fff;
  padding: 2px 5px;
  vertical-align: top;
  position: absolute;
  top: 0%;
  margin-left: 20px;
  border-radius: 10px;
}
.item_img {
  display: block;
  position: relative;
  overflow: hidden;
  background: #f3f3f3;
  height: 150px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}
.item_img img {
  height: 120px;
  margin-top: 15px;
}
.item_price {
  color: var(--ion-color-primary);
  margin: 10px;

}
.item_fav {
  height: 27px;
  width: 27px;
  border-radius: 12px;
  background: #d6d5d5;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
}
.flex_grow {
  flex-grow: 1;
}
.product_img {
  display: block;
  position: relative;
  background: #f3f3f3;
  border-radius: 15px 0px 0px 15px;
  padding: 10px;
}
.product_price {
  font-size:16px;
  font-weight: 500 !important;
  color: var(--ion-color-primary);
  margin: 7px 0px 0px;
}
.delete_btn {
  font-size: 20px;
  color: #fc6969;
}
.product_qty {
  position: relative;
  border: 1px solid #b1b0b0;
  color: var(--ion-color-secondary);
  padding: 4px;
  height: 25px;
  font-weight: bold;
  top: -7px;
  border-radius: 6px;
  margin: 0px 10px;
}
.productqty_btn {
  font-size: 15px;
  background: #f5f5f5;
  padding: 5px;
  border-radius: 6px;
  color: var(--ion-color-secondary);
}
.direction_icon {
  text-align: center;
  border: 1px solid #ebf0f9 !important;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgb(198 218 248 / 25%);
  ion-icon {
    font-size: 25px;
    height: 100%;
    color: white;
  }
}
.diricon_bgp {
  background: var(--ion-color-primary);
}
.diricon_bgs {
  background: var(--ion-color-secondary);
}
.diricon_bg {
  background: #d4d4d4;
}
.summary_box {
  background: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
}
.summary_box p {
  margin: 10px 0px;
}
.border_top {
  border-top: 1px solid rgba(187, 187, 187, 0.33);
}
.segment_box {
  background: #e5eaf1;
  border-radius: 11px;
  color: #292d32;
}
.segment_box .seg_btn {
  --indicator-box-shadow: transparent !important;
  --indicator-color: transparent !important;
  --background-checked: white !important;
  --color-checked: var(--ion-color-primary);
  border-radius: 10px !important;
  text-transform: none;
  margin: 5px;
  min-width: 48%;
  min-height: 40px;
}
.seg_btn .segbtn_icon {
  position: absolute;
  margin-left: -30px;
  margin-top: -1px;
  font-size: 18px;
}
.order_btn {
  width: calc(50% - 10px) !important;
}
.profile_block {
  height: 120px;
  background: url(../assets/profilebg.png) 0 0/100% 100%;
  margin: 15px 15px 0px;
  padding: 20px 10px;
}
.profile_image {
  position: relative;
  display: block;
  height: 76px;
  width: 76px;
  border: 4px solid #8ec8b0;
  border-radius: 80px;
  overflow: hidden;
}
.profile_image img {
  height: 76px;
  width: 76px;
}
.list_icon {
  color: rgb(20, 20, 20);
}
.logout {
  --background: #fc6969 !important;
  width: calc(100% - 30px) !important;
  margin: 15px 0px 15px 15px !important;
}
.logout ion-icon {
  position: absolute;
  right: 0;
}

.productgrid{
  min-height:290px
}
