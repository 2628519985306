/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "./theme/general.scss";
// Add base app styles
@import "./theme/theme.scss";



  .alert-wrapper {
    max-width: 85% !important;
  }
  



.subscription-popalert{
    .alert-wrapper {
      max-width: 95% !important;
    }
    .meme{
      color:blue
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }
    
    td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    }
    
    .error-field {
    margin: 0 !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    font-size: 12px;
    color:red;
    padding-right: 10px;
    padding-bottom: 10px;
    }
  }
  .productgrid{
    min-height:290px
  }


  .center-content{
    width: 100%;
    
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15vh;
    height: 100%;
  
   
  }
  .split-pane-visible >.split-pane-side {
    min-width: 280px !important;
    max-width: 280px !important;
  }

